import { Component } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { User } from '@app/data/models/user.model';
import { SiteService } from '@app/data/services/site.service';
import { MongoAbility, createMongoAbility } from '@casl/ability';
import { ThemeService } from '@core/services/theme.service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { filter } from 'rxjs';
import { environment as env } from '@env';
import { trigger, state, style, transition, animate } from '@angular/animations';

export enum PermissionAction {
  MANAGE = 'manage',
  CREATE = 'create',
  READ = 'read',
  UPDATE = 'update',
  DELETE = 'delete',
}
export type PermissionObjectType = any;
export type AppAbility = MongoAbility<[PermissionAction, PermissionObjectType]>;
export interface PermissionCondition { }
interface CaslPermission {
  action: PermissionAction;
  subject: string;
  conditions?: PermissionCondition;
}
function parseCondition(
  condition: PermissionCondition,
  variables: Record<string, any>
): PermissionCondition | null {
  if (!condition) return null;
  const parsedCondition: any = {};
  for (const [key, rawValue] of Object.entries(condition)) {
    if (rawValue !== null && typeof rawValue === 'object') {
      const value = parseCondition(rawValue, variables);
      parsedCondition[key] = value;
      continue;
    }
    if (typeof rawValue !== 'string') {
      parsedCondition[key] = rawValue;
      continue;
    }
    // find placeholder "${}""
    const matches = /\${([a-zA-Z1-9])*}/.exec(rawValue);
    if (!matches) {
      parsedCondition[key] = rawValue;
      continue;
    }
    let varName = matches[0].replace('${', '').replace('}', '');
    const value = variables[varName];
    if (typeof value === 'undefined') {
      throw new ReferenceError(`Variable ${value} is not defined`);
    }
    parsedCondition[key] = value;
  }
  return parsedCondition;
}

interface NavBarPermission {
  action: PermissionAction;
  subject: string;
  operator?: ValidOperatorType;
}

const validOperatorTypes = ['and', 'or'] as const;
type ValidOperatorType = (typeof validOperatorTypes)[number];

interface NavBarSection {
  sectionName: string;
  sectionCode: string;
  divider: boolean;
  sectionLinks: Array<NavBarLink>;
  folded?: boolean;
  rotatedState: string;
  requiredPermissions?: Array<NavBarPermission>;
}

interface NavBarLink {
  name: string;
  icon: string;
  path: string;
  pathFunc?: Function;
  showSubmenu: boolean;
  childMenu?: Array<any>;
  subMenu?: Array<any>;
  requiredPermissions?: Array<NavBarPermission>;
  tooltip?: string;
  disabled?: boolean;
  chips?: [];
  onClick?: () => void;
}

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrl: './nav.component.scss',
  providers: [],
  animations: [

    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(90deg)' })),
      transition('rotated => default', animate('100ms ease-out')),
      transition('default => rotated', animate('100ms ease-in'))
    ])
  ],
})
export class NavComponent {
  sidenavWidth = '14em';
  sidenavWidthIsMax = true;
  isSidenavOpened = true;
  navBarAlreadyLoad = false;
  user$ = this.authService.user;
  selectedSite: any;
  selectedSiteId: string = '';

  // Nav Links
  sidenavLinks: Array<NavBarSection> = [];

  appAbility = createMongoAbility<AppAbility>();

  environment = env.environment;

  constructor(
    private siteService: SiteService,
    public themeService: ThemeService,
    private authService: AuthService,
    private _activatedroute: ActivatedRoute,

    private ssrCookieService: SsrCookieService,
    private router: Router
  ) {
    this.sidenavLinks = [];
  }

  ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {

        this.user$.subscribe((usr: User | null) => {
          if (usr == null) return;
          const urlFragments = this.router.url.split('/');
          const idx = urlFragments.indexOf('sites');
          const clientIdx = urlFragments.indexOf('clients');
          if (idx !== -1 && urlFragments.length > idx + 1 && clientIdx === -1) {
            if (urlFragments[idx + 1] !== this.selectedSiteId) {
              this.selectedSiteId = urlFragments[idx + 1];
              this.selectedSite = usr.sites?.find(
                (s) => s.id === this.selectedSiteId
              );
              this.authService.updateCurrentSiteCookie(this.selectedSiteId);
            }
          }
        });
      });
    this.constructSideNavLinks(this.router.url);
  }

  getEnvLabel() {
    switch (this.environment) {
      case 'development':
        return 'Développement';
      case 'release':
        return 'Recette';
      case 'production':
        return 'Production';
      case 'local':
        return 'Local';
      default:
        return '';
    }
  }

  private getSectionOpened(section: string, url: string) {
    const cookie = this.ssrCookieService.get(`${section}-opened`);
    if (cookie === '') {
      this.ssrCookieService.set(`${section}-opened`, url.startsWith(`/${section}`) ? 'true' : 'false');
      return url.startsWith(`/${section}`);
    }
    return cookie === 'true';
  }

  constructSideNavLinks(url: string) {
    this.user$.subscribe((usr: User | null) => {
      if (usr == null) return;
      const user = usr as User;

      this.sidenavLinks = [];

      // this._activatedroute.url.subscribe((url) => {
      //   const urlFragments = this.router.url.split('/');
      //   const idx = urlFragments.indexOf('sites');
      //   const clientIdx = urlFragments.indexOf('clients');
      //   if (idx !== -1 && urlFragments.length > idx + 1 && clientIdx === -1) {
      //     if (urlFragments[idx + 1] !== this.selectedSiteId) {
      //       this.selectedSiteId = urlFragments[idx + 1];
      //       this.selectedSite = usr.sites?.find(
      //         (s) => s.id === this.selectedSiteId
      //       );
      //       this.authService.updateCurrentSiteCookie(this.selectedSiteId);
      //     }
      //   }
      // });

      let urlIsSite = this.getSectionOpened('sites', url);
      let urlIsCatalog = this.getSectionOpened('catalog', url);
      let urlIsClient = this.getSectionOpened('clients', url);
      let urlIsAdmin = this.getSectionOpened('administration', url);
      let urlIsDocumentation = this.getSectionOpened('documentation', url);


      // SIDENAV MANAGEMENT
      if ((user.sites?.length ?? 0) > 0) {
        this.addSiteNavbar(urlIsSite); // Add Basic Navbar
      }

      this.addCatalogNavbar(urlIsCatalog); // Add Catalog Navbar

      // If Admin - Add Admin Navbar
      if (user?.role && user?.role.level <= 2 && user.holding != null) {
        this.addClientAdministrationNavbar(urlIsClient);
      }

      if (user?.role && user?.role.level <= 1) {
        this.addAppAdministrationNavbar(urlIsAdmin);
      }

      this.addDocumentationNavbar(urlIsDocumentation);

      this.navBarAlreadyLoad = true;

      // User ability
      if (user && user.role?.permissions) {
        const caslPermissions: CaslPermission[] = user.role.permissions.map(
          (p: any) =>
          ({
            action: p.action,
            subject: p.subject,
            conditions: parseCondition(p.condition, user),
          } as CaslPermission)
        );
        this.appAbility = createMongoAbility<AppAbility>(caslPermissions);
      }
    });
  }

  getLogoImg() {
    let img = 'logo.png';
    // this.isDarkTheme$.subscribe(valueTheme => img = valueTheme ? 'whiteLogo.png' : 'logo.png')
    return img;
  }

  /*  -------------------------------- 🏰 CASL Management  --------------------------------  */

  readCaslPermissions(requiredPermissions: Array<NavBarPermission>) {
    if (requiredPermissions.length > 0) {
      return requiredPermissions.some((perm: NavBarPermission) =>
        this.evaluateCondition(perm)
      );
    }
    return true;
  }

  evaluateCondition(permission: NavBarPermission): boolean {
    return this.appAbility.can(permission.action, permission.subject);
  }

  /*  -------------------------------- / 🏰 CASL Management  --------------------------------  */

  /*  --------------------------------   Site Management  --------------------------------  */
  updateActiveSite(event: any) {
    if (this.router.url.includes('sites')) {
      let urlFragments = this.router.url.split('/');
      urlFragments[urlFragments.indexOf('sites') + 1] = event.value.id;
      this.router.navigate(urlFragments, {
        onSameUrlNavigation: 'reload',
      });
    } else {
      this.router.navigate(['/sites', event.value.id, 'home'], {
        onSameUrlNavigation: 'reload',
      });
    }
  }

  compareObjects(o1: any, o2: any) {
    if (o1.id == o2.id) return true;
    else return false;
  }
  /*  --------------------------------  /  Site Management  --------------------------------  */

  /*  --------------------------------  🛠 Actions Buttons  --------------------------------  */

  // Change current Theme ( Light/Dark )
  toggle({ checked }: MatSlideToggleChange): void {
    this.themeService.setMode(checked);
  }

  // Increase / Reduce the size of the sidenav
  toggleSidenav() {
    this.sidenavWidth = this.sidenavWidth == '6em' ? '14em' : '6em';
    this.sidenavWidthIsMax = this.sidenavWidth == '6em' ? false : true;
  }

  // SignOut current user
  signOut() {
    this.sidenavLinks = [];
    this.authService.signOut();
  }

  addSiteNavbar(opened: boolean) {
    const indexSiteNavbar = this.sidenavLinks.findIndex(
      (section) => section.sectionName == 'MON SITE'
    );
    if (indexSiteNavbar == -1) {
      this.sidenavLinks.push({
        sectionName: 'MON SITE',
        sectionCode: 'sites',
        divider: true,
        folded: !opened,
        rotatedState: 'default',
        sectionLinks: [
          {
            name: 'Accueil',
            icon: 'home-light',
            path: `/sites/${this.ssrCookieService.check('current-site')
              ? this.ssrCookieService.get('current-site')
              : ''
              }/home`,
            pathFunc: () => `/sites/${this.ssrCookieService.check('current-site')
              ? this.ssrCookieService.get('current-site')
              : ''
              }/home`,
            showSubmenu: false,
          },
          {
            name: 'Mes références',
            icon: 'clipboard-tasks-light',
            path: `/sites/${this.ssrCookieService.check('current-site')
              ? this.ssrCookieService.get('current-site')
              : ''
              }/registered-devices`,
            pathFunc: () => `/sites/${this.ssrCookieService.check('current-site')
              ? this.ssrCookieService.get('current-site')
              : ''
              }/registered-devices`,
            showSubmenu: false,
          },
          {
            name: 'Mes lots',
            icon: 'delivery-box-1-light',
            path: `/sites/${this.ssrCookieService.check('current-site')
              ? this.ssrCookieService.get('current-site')
              : ''
              }/medical-device-batches`,
            pathFunc: () => `/sites/${this.ssrCookieService.check('current-site')
              ? this.ssrCookieService.get('current-site')
              : ''
              }/medical-device-batches`,
            showSubmenu: false,
            requiredPermissions: [
              { action: PermissionAction.READ, subject: 'MedicalDeviceBatch' },
            ],
          },
          {
            name: 'Mes alertes',
            icon: 'folder-light',
            path: `/sites/${this.ssrCookieService.check('current-site')
              ? this.ssrCookieService.get('current-site')
              : ''
              }/medical-device-alerts`,
            pathFunc: () => `/sites/${this.ssrCookieService.check('current-site')
              ? this.ssrCookieService.get('current-site')
              : ''
              }/medical-device-alerts`,
            showSubmenu: false,
            requiredPermissions: [
              { action: PermissionAction.READ, subject: 'MedicalDeviceAlert' },
            ],
          },
          {
            name: 'Mes déclarations',
            icon: 'event-light',
            path: `/sites/${this.ssrCookieService.check('current-site')
              ? this.ssrCookieService.get('current-site')
              : ''
              }/material-safeties`,
            pathFunc: () => `/sites/${this.ssrCookieService.check('current-site')
              ? this.ssrCookieService.get('current-site')
              : ''
              }/material-safeties`,
            tooltip: 'Disponible prochainement',
            disabled: this.environment === 'production',
            showSubmenu: false,
            requiredPermissions: [
              { action: PermissionAction.READ, subject: 'MaterialSafety' },
            ],
          },
          {
            name: 'Mon abonnement',
            icon: 'subscription-light',
            path: `/sites/${this.ssrCookieService.check('current-site')
              ? this.ssrCookieService.get('current-site')
              : ''
              }/subscription`,
            pathFunc: () => `/sites/${this.ssrCookieService.check('current-site')
              ? this.ssrCookieService.get('current-site')
              : ''
              }/subscription`,
            onClick: () => {
              this.siteService
                .getBillingPortalSessionUrl(
                  this.ssrCookieService.check('current-site')
                    ? this.ssrCookieService.get('current-site')
                    : ''
                )
                .subscribe((session) => {
                  window.location.href = session.url;
                });
            },
            showSubmenu: false,
            requiredPermissions: [
              { action: PermissionAction.READ, subject: 'Subscription' },
            ],
          },
        ],
      });
    }
  }

  addCatalogNavbar(opened: boolean) {
    const indexCatalogNavbar = this.sidenavLinks.findIndex(
      (section) => section.sectionName == "CATALOGUE • CHECK'OP"
    );
    if (indexCatalogNavbar == -1) {
      this.sidenavLinks.push({
        sectionName: "CATALOGUE • CHECK'OP",
        sectionCode: 'catalog',
        divider: true,
        folded: !opened,
        rotatedState: 'default',
        sectionLinks: [
          {
            name: 'Dispositifs Médicaux',
            icon: 'health-clipboard-light',
            path: '/catalog/medical-devices',
            showSubmenu: false,
            requiredPermissions: [
              { action: PermissionAction.READ, subject: 'MedicalDevice' },
            ],
          },
          {
            name: 'Famille de dispositifs',
            icon: 'bandage',
            path: '/catalog/medical-device-families',
            showSubmenu: false,
            requiredPermissions: [
              { action: PermissionAction.READ, subject: 'MedicalDeviceFamily' },
            ],
          },
          {
            name: 'Fabricants',
            icon: 'factory-light',
            path: '/catalog/manufacturers',
            showSubmenu: false,
            requiredPermissions: [
              { action: PermissionAction.READ, subject: 'Manufacturer' },
            ],
          },
          {
            name: 'Fournisseurs',
            icon: 'truck-2-light',
            path: '/catalog/suppliers',
            showSubmenu: false,
            requiredPermissions: [
              { action: PermissionAction.READ, subject: 'Supplier' },
            ],
          },
          /*   {
               name: "Entités Légales",
               icon: 'building-hospital-light',
               path: '/legal-entities',
               showSubmenu: false,
               requiredPermissions: [
                 { action: 'read', subject: 'LegalEntity' },
               ],
             }
     */
        ],
      });
    }
  }

  addClientAdministrationNavbar(opened: boolean) {
    const indexClientAdministrationNavbar = this.sidenavLinks.findIndex(
      (section) => section.sectionName == 'ADMINISTRATION • CLIENT'
    );
    if (indexClientAdministrationNavbar == -1) {
      this.sidenavLinks.push({
        sectionName: 'ADMINISTRATION • CLIENT',
        sectionCode: 'clients',
        // requiredPermissions: [
        //   { action: 'read', subject: 'Site' },
        //   { action: 'create', subject: 'Site' },
        // ],
        folded: !opened,
        rotatedState: 'default',
        divider: true,
        sectionLinks: [
          {
            name: 'Mes sites',
            icon: 'map-location-light',
            path: `/clients/${this.ssrCookieService.check('current-holding')
              ? this.ssrCookieService.get('current-holding')
              : ''
              }/sites`,
            showSubmenu: false,
            requiredPermissions: [
              { action: PermissionAction.READ, subject: 'Site' },
            ],
          },
          {
            name: 'Mes utilisateurs',
            icon: '3-user-light',
            path: `/clients/${this.ssrCookieService.check('current-holding')
              ? this.ssrCookieService.get('current-holding')
              : ''
              }/users`,
            showSubmenu: false,
            requiredPermissions: [
              { action: PermissionAction.READ, subject: 'Users' },
            ],
          },
          // {
          //   name: "Licences",
          //   icon: 'bank-card-star',
          //   path: '',
          //   showSubmenu: false,
          //   requiredPermissions: [
          //     { action: 'read', subject: 'Site' },
          //   ],
          // },
        ],
      });
    }
  }

  addAppAdministrationNavbar(opened: boolean) {
    const indexAppAdministrationNavbar = this.sidenavLinks.findIndex(
      (section) => section.sectionName == "ADMINISTRATION • CHECK'OP"
    );
    if (indexAppAdministrationNavbar == -1) {
      this.sidenavLinks.push({
        sectionName: "ADMINISTRATION • CHECK'OP",
        sectionCode: 'administration',
        requiredPermissions: [
          { action: PermissionAction.MANAGE, subject: 'all' },
        ],
        folded: !opened,
        rotatedState: 'default',
        divider: true,
        sectionLinks: [
          {
            name: 'Clients',
            icon: 'building-hospital-light',
            path: '/administration/clients',
            showSubmenu: false,
            requiredPermissions: [
              { action: PermissionAction.READ, subject: 'Site' },
            ],
          },
          {
            name: 'Utilisateurs',
            icon: '3-user-light',
            path: '/administration/users',
            showSubmenu: false,
            requiredPermissions: [
              { action: PermissionAction.READ, subject: 'Users' },
            ],
          },
          // {
          //   name: "Licences",
          //   icon: 'bank-card-star',
          //   path: '',
          //   showSubmenu: false,
          //   requiredPermissions: [
          //     { action: 'read', subject: 'Site' },
          //   ],
          // },
        ],
      });
    }
  }

  addDocumentationNavbar(opened: boolean) {
    const indexAppAdministrationNavbar = this.sidenavLinks.findIndex(
      (section) => section.sectionName == "DOCUMENTATION"
    );
    if (indexAppAdministrationNavbar == -1) {
      this.sidenavLinks.push({
        sectionName: "DOCUMENTATION",
        sectionCode: 'documentation',
        folded: !opened,
        rotatedState: 'default',
        divider: true,
        sectionLinks: [
          {
            name: 'Manuel Utilisateur',
            icon: 'document-light',
            path: '/documentation/user-manual',
            showSubmenu: false,
          },
          {
            name: 'Guide des pictogrammes',
            icon: 'info-square-broken',
            path: '/documentation/pictogram-guide',
            showSubmenu: false,
          },
          {
            name: 'Guide matério-vigilance',
            icon: 'event-light',
            path: '/documentation/material-vigilance-guide',
            showSubmenu: false,
          },
        ],
      });
    }
  }

  foldSection(section: NavBarSection) {
    section.folded = !section.folded;
    section.rotatedState = section.folded ? 'rotated' : 'default';
    this.ssrCookieService.set(`${section.sectionCode}-opened`, section.folded ? 'false' : 'true');
  }
}
